<template>
  <q-layout view="hHh Lpr fFf">
    <q-layout-header id="appHeader">
      <q-toolbar color="primary" inverted dir="ltr">
        <q-btn size="1.2em" round @click="drawerTriggerShow">
          <q-icon name="ion-arrow-forward"/>
        </q-btn>
        <q-toolbar-title id="appTitle">
          <template v-if="authenticated && $store.state.app.io.name">
            <span class="title uppercase text-weight-bold">{{ $store.state.app.io.name ? $t('HELLO_NAME', { name: $store.state.app.io.name.split(' ')[0] }) : $t('HELLO_FRIEND') }}</span>
          </template>
          <template v-else>
            <span class="title uppercase text-weight-bold">{{ $t('HELLO_FRIEND') }}</span>
          </template>
          <span class="title uppercase text-weight-bold text-attention" slot="subtitle">{{ $t($route.name) }}</span>
        </q-toolbar-title>
        <q-btn v-if="authenticated && $route.name === 'CHANNEL.LABEL_TT'" round size="1.2em" @click="goHome">
          <q-icon name="ion-close"/>
        </q-btn>
        <q-btn v-else round size="1.2em" class="invisible"></q-btn>
      </q-toolbar>
    </q-layout-header>
    <!-- slide menu -->
    <q-layout-drawer side="left" behavior="mobile" v-model="drawerShow">
      <q-list highlight no-border link class="text-system-brand text-weight-medium">
        <q-item @click.native="drawerTriggerHide">
          <q-item-side>
            <!-- <img width="40" src="https://www.expocitydubai.com/_nuxt/img/logo-flower@2x.82734e6.png" alt="Expo City Dubai" class="non-selectable brand-wordmark"/> -->
            <img v-if="settings_branding" width="118" src="https://www.expocitydubai.com/_nuxt/img/logo-en@2x.05ed9b9.png" alt="Expo City Dubai" class="non-selectable brand-wordmark"/>
            <img v-else width="60" src="/statics/icons/icon-gold.svg" alt="wings" class="non-selectable brand-wordmark" style=""/>
          </q-item-side>
          <q-item-main>
            <!--
            <q-item-tile label class="text-weight-semibold">
              Expo City Dubai
            </q-item-tile>
            -->
            <q-item-tile sublabel>
              &nbsp;
            </q-item-tile>
          </q-item-main>
          <q-item-side right icon="ion-arrow-back" color="primary" class="on-left-lg"/>
        </q-item>
        <q-list-header v-if="!isProduction && authenticated" class="no-margin-top text-weight-bold text-grey">Happenings</q-list-header>
        <q-item v-if="!isProduction && authenticated" item to="/today">
          <q-item-side class="text-center" :color="anyDarkmode ? '' : 'blue-grey-10'" icon="ion-today" />
          <q-item-main label="Today" sublabel="Breeze through today"/>
        </q-item>
        <q-item-separator/>

        <!--
        <span class="block font-size-60p text-secondary spaced-width text-system-brand">
          {{ isBusinessPage }}
        </span>
        -->

        <q-list-header v-if="!isBusinessPage" class="no-margin-top text-weight-bold text-grey">Services</q-list-header>
        <q-item v-if="!authenticated" tag="label" item @click.native="dialogLoginShowOpen()">
          <q-item-side class="text-center">
            <q-icon name="ion-log-in" :color="anyDarkmode ? '' : 'blue-grey-10'" size="33px"/>
          </q-item-side>
          <q-item-main>
            <q-item-tile label class="capitalize text-primary text-weight-semibold">{{ $t('DRAWER.ITEM.GET_STARTED.L') }}</q-item-tile>
            <q-item-tile sublabel>{{ $t('DRAWER.ITEM.GET_STARTED.D') }}</q-item-tile>
          </q-item-main>
        </q-item>
        <!--
        <q-item v-if="!authenticated" item to="/login">
          <q-item-side class="text-center" :color="anyDarkmode ? '' : 'blue-grey-10'" icon="ion-log-in" />
          <q-item-main :label="$t('DRAWER.ITEM.GET_STARTED.L')" :sublabel="$t('DRAWER.ITEM.GET_STARTED.D')"/>
        </q-item>
        -->
        <!-- <q-list-header class="no-margin-top text-weight-bold text-grey">Account</q-list-header> -->
        <!--
        <q-item v-if="authenticated" item style="padding:18px" @click.native="accountDialogTrigger()">
          <q-item-side class="text-center" :color="anyDarkmode ? '' : 'blue-grey-10'" icon="ion-contact" />
          <q-item-main class="text-weight-semibold text-system-brand">
            <q-item-tile v-if="user_details.name" dir="ltr" class="ellipsis">
              {{ user_details.name }}
            </q-item-tile>
            <q-item-tile hidden v-if="user_details.email" style="font-size:14px" class="caption ellipsis">
              {{ user_details.email }}
            </q-item-tile>
            <q-item-tile v-if="user_details.phone" style="font-size:14px" class="caption ellipsis text-fixedwidth-brand">
              {{ user_details.phone }}
            </q-item-tile>
          </q-item-main>
          <q-item-side right class="text-right" icon="ion-more" color="grey"/>
        </q-item>
        -->
        <!--
        <q-item v-if="authenticated" item @click.native="profileDialogTrigger()">
          <q-item-side v-if="$store.state.app.io.picture" class="text-center" :color="anyDarkmode ? '' : 'blue-grey-10'" :avatar="$store.state.app.io.picture"/>
          <q-item-side v-else class="text-center" :color="anyDarkmode ? '' : 'blue-grey-10'" icon="ion-contact"/>
          <q-item-main>
            <q-item-tile v-if="user_details.name" label class="text-weight-bold">{{ user_details.name }}</q-item-tile>
            <q-item-tile v-else label class="text-attention font-size-80p text-weight-semibold">Tap to setup your name</q-item-tile>
            <q-item-tile sublabel>{{ user_details.phone }}</q-item-tile>
          </q-item-main>
        </q-item>
        <q-item item @click.native="accountDialogTrigger()">
          <q-item-side class="text-center" :color="anyDarkmode ? '' : 'blue-grey-10'" icon="ion-switch" />
          <q-item-main label="Settings" sublabel="Preferences and updates"/>
        </q-item>
        <q-item-separator/>
        -->
        <q-list-header v-if="isBusinessPage" class="no-margin-top text-weight-bold text-grey">Manage Channel</q-list-header>
        <q-item v-if="isBusinessPage && authenticated" @click.native="appEmit('bchannel-today')">
          <q-item-side class="text-center" icon="ion-calendar"/>
          <q-item-main label="Today"/>
        </q-item>
        <q-item v-if="isBusinessPage && authenticated" @click.native="appEmit('bchannel-loyalty')">
          <q-item-side class="text-center" icon="ion-gift"/>
          <q-item-main label="Loyalty"/>
        </q-item>
        <q-item-separator v-if="isBusinessPage"/>
        <q-item item v-if="isBusinessPage && authenticated" @click.native="appEmit('bchannel-qrcode')">
          <q-item-side class="text-center">
            <img src="/statics/_demo/qrcode_primary.svg" width="22"/>
          </q-item-side>
          <q-item-main label="QR Code"/>
        </q-item>
        <q-item item v-if="isBusinessPage && authenticated" @click.native="appEmit('bchannel-settings')">
          <q-item-side class="text-center" icon="ion-cube"/>
          <q-item-main label="Profile"/>
        </q-item>
        <q-item item v-if="isBusinessPage && authenticated" color="text-protect" @click.native="appEmit('bchannel-exit')">
          <q-item-side class="text-center text-protect" icon="ion-close-circle" color="text-protect"/>
          <q-item-main label="Exit Channel" class="text-protect text-weight-semibold"/>
        </q-item>

        <q-item v-if="!isBusinessPage && authenticated" item exact to="/">
          <q-item-side class="text-center" :color="anyDarkmode ? '' : 'blue-grey-10'" icon="ion-heart"/>
          <q-item-main label="Subscriptions" sublabel="Your preferred services"/>
          <!-- <q-item-side right><q-chip>22</q-chip></q-item-side> -->
        </q-item>
        <q-item v-if="false && authenticated" item exact to="/requests">
          <q-item-side class="text-center" :color="anyDarkmode ? '' : 'blue-grey-10'" icon="ion-swap"/>
          <q-item-main label="Requests" sublabel="Your active requests"/>
          <!-- <q-item-side right><q-chip small color="educate">2</q-chip></q-item-side> -->
        </q-item>
        <q-item v-if="authenticated && !isProduction" item exact to="/memberships">
          <q-item-side class="text-center" :color="anyDarkmode ? '' : 'blue-grey-10'" icon="ion-albums"/>
          <q-item-main label="Memberships" sublabel="Your paid subscriptions"/>
        </q-item>
        <q-item v-if="authenticated && !isProduction" item exact to="/nests">
          <q-item-side class="text-center" :color="anyDarkmode ? '' : 'blue-grey-10'" icon="ion-apps"/>
          <q-item-main label="Nests" sublabel="Create public list of channels"/>
        </q-item>
        <q-item v-if="authenticated && !isProduction" item to="/notifications">
          <q-item-side class="text-center" :color="anyDarkmode ? '' : 'blue-grey-10'" icon="ion-notifications" />
          <q-item-main label="Notifications" sublabel="Your service status changes"/>
        </q-item>
        <!--q-item link tag="label" item @click.native="viewChannel">
          <q-item-side class="text-center">
            <q-icon name="ion-radio" size="33px"/>
          </q-item-side>
          <q-item-main>
            <q-item-tile label>View Channel</q-item-tile>
            <q-item-tile sublabel>Load channel with ID</q-item-tile>
          </q-item-main>
        </q-item-->
        <q-item-separator v-if="!isBusinessPage"/>

        <q-list-header v-if="!isBusinessPage" class="text-weight-bold text-grey">Businesses</q-list-header>
        <p v-if="!authenticated" class="text-grey-10 text-weight-regular"
          style="
          padding: 20px;
          background-color: rgba(75, 226, 73, 0.15);
          margin: 10px;
          margin-top: -10px;
          border-radius: 0.5em;
          ">
          <span class="block font-size-80p"><strong class="text-primary">Ready to go live?</strong> Setup your business channel in minutes and share features, status, and promotions with your customers. <br><br>Sign up now for a <strong class="text-empower">free 30-day trial</strong>. No credit card required.</span>
        </p>
        <q-item v-if="!isBusinessPage && authenticated" item to="/business">
          <q-item-side class="text-center" :color="anyDarkmode ? '' : 'blue-grey-10'" icon="ion-radio" />
          <q-item-main label="Manage Channels" sublabel="Your business channels"/>
        </q-item>
        <q-item v-if="authenticated && !isProduction" item>
          <q-item-side class="text-center" :color="anyDarkmode ? '' : 'blue-grey-10'" icon="ion-code" />
          <q-item-main label="Integrations" sublabel="Services apps and devices"/>
        </q-item>

        <q-item-separator/>
        <q-list-header class="no-margin-top text-weight-bold text-grey">Account</q-list-header>
        <q-item item @click.native="accountDialogTrigger()">
          <q-item-side class="text-center" :color="anyDarkmode ? '' : 'blue-grey-10'" icon="ion-switch" />
          <q-item-main label="Settings" sublabel="Preferences and updates"/>
        </q-item>
        <!--
        <q-item v-if="authenticated" item to="/plans">
          <q-item-side class="text-center" :color="anyDarkmode ? '' : 'blue-grey-10'" icon="ion-list-box" />
          <q-item-main label="Plans" sublabel="Subscriptions and features"/>
        </q-item>
        <q-item v-if="authenticated" item @click.native="dialogWallet">
          <q-item-side class="text-center" :color="anyDarkmode ? '' : 'blue-grey-10'" icon="ion-wallet" />
          <q-item-main>
            <q-item-tile label>Wallet</q-item-tile>
            <q-item-tile sublabel>{{ user_details.currency || 'AED' }} {{ user_details.funds / 100 | nformat('0,0.00') }}</q-item-tile>
          </q-item-main>
          <q-item-side right><q-btn v-show="fundsRefreshing" :loading="fundsRefreshing"/></q-item-side>
        </q-item>
        -->

        <!-- <q-item-separator/> -->
        <!-- <q-list-header v-if="authenticated" class="no-margin-top text-weight-bold text-grey">Profile</q-list-header> -->
        <q-item v-if="authenticated" item @click.native="profileDialogTrigger()">
          <q-item-side v-if="$store.state.app.io.picture" class="text-center" :color="anyDarkmode ? '' : 'blue-grey-10'" :avatar="$store.state.app.io.picture"/>
          <q-item-side v-else class="text-center" :color="anyDarkmode ? '' : 'blue-grey-10'" icon="ion-contact"/>
          <q-item-main>
            <q-item-tile v-if="user_details.name" label class="text-weight-bold">{{ user_details.name }}</q-item-tile>
            <q-item-tile v-else label class="text-attention font-size-80p text-weight-semibold">Tap to setup your name</q-item-tile>
            <q-item-tile sublabel>{{ user_details.email }}</q-item-tile>
          </q-item-main>
        </q-item>

        <q-item-separator/>
        <p class="text-grey-5 text-weight-regular" style="padding: 20px; padding-top: 10px">
          <strong class="block font-size-80p">Wings v{{ $store.state.app.version }}
            <q-chip v-if="!isProduction" class="q-chip-na q-chip-denser margin-xs-l" dense>dev</q-chip>
            <q-chip v-if="isDemoPage" class="q-chip-pro q-chip-denser margin-xs-l" dense>AI</q-chip>
            <q-chip v-if="$store.state.app.version_flag" class="q-chip-pro q-chip-denser margin-xs-l" dense>{{ $store.state.app.version_flag }}</q-chip>
          </strong>
          <span class="block font-size-60p on-top-sm">{{ $store.state.app.version_date }}</span>
          <span class="block font-size-60p on-top-xs">&copy; {{ (new Date()).getFullYear() }} letsbutterfly, Inc. All rights reserved.</span>
          <span class="block font-size-60p on-top-xs">
            <q-btn @click="dialogAboutShowOpen" class="inline-block text-secondary font-size-100p spaced-width" dense style="margin-left: -5px">
              <span class="text-system-brand">About</span>
            </q-btn>
            <q-btn @click="dialogLegalTermsShowOpen" class="inline-block text-secondary font-size-100p spaced-width" dense>
              <span class="text-system-brand">Terms of Use</span>
            </q-btn>
            <q-btn @click="dialogLegalPrivacyShowOpen" class="inline-block text-secondary font-size-100p spaced-width" dense>
              <span class="text-system-brand">Privacy Policy</span>
            </q-btn>
          </span>
        </p>
        <!-- debug::
        <p class="text-purple text-weight-regular" style="padding: 20px; padding-top: 0px; margin-top: -20px">
          <span class="block font-size-60p on-top-xs text-fixedwidth-brand">
            {{ $route.path }}
          </span>
        </p>
        -->
      </q-list>
    </q-layout-drawer>
    <q-page-container>
      <transition
        appear
        enter-active-class="animated animated400 slideInUp"
        leave-active-class="animated animated400 slideOutDown"
      >
        <router-view
          v-on:startAjaxBar="onStartAjaxBar"
          v-on:stopAjaxBar="onStopAjaxBar"
          :lang="lang"
          :auth="auth"
          :authenticated="authenticated"
          :ecosystem="ecosystem"
          :anyDarkmode="anyDarkmode"
          :soundPlay="soundPlay"
          :soundFade="soundFade"
          :modalAdapt="modalAdapt"
          :accountDialogTrigger="accountDialogTrigger"
          :drawerTriggerShow="drawerTriggerShow"
          :dialogAboutShowOpen="dialogAboutShowOpen"
          :dialogLoginShowOpen="dialogLoginShowOpen"
          :isProduction="isProduction"
        />
      </transition>
      <div class="text-center">
        <img v-if="settings_branding" width="118" src="https://www.expocitydubai.com/_nuxt/img/logo-en@2x.05ed9b9.png" alt="Expo City Dubai" class="non-selectable brand-wordmark"/>
        <img v-else width="60" src="/statics/icons/icon-gold.svg" alt="wings" class="non-selectable brand-wordmark" style="margin-top: 60px; margin-bottom: 10px"/>
        <p class="font-size-80p text-grey-5 text-weight-regular block" style="padding-bottom: 20px">
          Wings v{{ $store.state.app.version }} &ndash; {{ $store.state.app.version_date }}
          <br>&copy; {{ (new Date()).getFullYear() }} letsbutterfly, Inc. All rights reserved.
        </p>
      </div>

    </q-page-container>

    <!--
      MODAL: Login (Email OTP)
    -->
    <q-modal id="dialogLogin" v-model="dialogLoginShow" position="bottom" class="appLayer dialog-item" enter-class="slideInUp animated800" leave-class="slideOutDown animated400">
      <q-modal-layout>
        <q-toolbar slot="header" inverted v-touch-pan.vertical.prevent.stop="modalAdapt" class="cursor-grab">
          <q-toolbar-title>Login</q-toolbar-title>
        </q-toolbar>
        <q-toolbar slot="header" inverted class="toolbar-overscroll-shadow">
          <q-card class="q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top">
            <q-card-main class="column justify-center full-height">
              <q-btn class="margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase" color="primary-light" text-color="primary" rounded @click.native="dialogLoginShowClose">
                <img :src="'/statics/_demo/' + (anyDarkmode ? 'chevron.compact.down_white.svg': 'chevron.compact.down_primary.svg')" height="10">
              </q-btn>
            </q-card-main>
          </q-card>
        </q-toolbar>
        <div class="layout-padding text-center items-center">
          <q-scroll-observable @scroll="toolbarShadowOnOverscroll"/>
          <div class="content-wrapper text-system-brand">
            <p class="on-bottom-xl text-gray text-center">
              Sign in to access your favorite services. <br>We will send you a One-Time-Passcode (OTP) to your email for login.
            </p>
            <p>
              <q-input @keyup.enter="loginEmailOTP()" :disabled="emailOTPProcessing" v-model.trim="emailOTP" type="email" clearable lower-case placeholder="Enter Email"/>
            </p>
            <p>
              <q-btn :loading="emailOTPProcessing" @click="loginEmailOTP()" class="full-width full-height text-weight-semibold" rounded color="primary" text-color="white">
                <span class="text-system-brand">Send Passcode</span>
              </q-btn>
            </p>
          </div>
        </div>
      </q-modal-layout>
    </q-modal>

    <!--
      MODAL: About
    -->
    <q-modal id="dialogAbout" v-model="dialogAboutShow" position="bottom" class="appLayer dialog-item"  enter-class="slideInUp animated800" leave-class="slideOutDown animated400">
      <q-modal-layout>
        <q-toolbar slot="header" inverted v-touch-pan.vertical.prevent.stop="modalAdapt" class="cursor-grab">
          <q-toolbar-title>{{ $t('DRAWER.ITEM.ABOUT.L') }}</q-toolbar-title>
        </q-toolbar>
        <q-toolbar slot="header" inverted class="toolbar-overscroll-shadow">
          <q-card class="q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top">
            <q-card-main class="column justify-center full-height">
              <q-btn class="margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase" color="primary-light" text-color="primary" rounded @click="dialogAboutShowClose">
                <img :src="'/statics/_demo/' + (anyDarkmode ? 'chevron.compact.down_white.svg': 'chevron.compact.down_primary.svg')" height="10">
              </q-btn>
            </q-card-main>
          </q-card>
        </q-toolbar>
        <div class="layout-padding text-center items-center">
          <q-scroll-observable @scroll="toolbarShadowOnOverscroll"/>
          <img class="non-selectable brand-wordmark" width="60" src="/statics/icons/icon-gold.svg" alt="wings" style="margin-top:-50px"/>
          <div class="content-wrapper text-system-brand" style="margin-top: -20px;">
            <h2 class="text-center">Welcome to Wings</h2>
            <p class="text-center text-primary"><strong>The future of service communication</strong></p>
            <p>At Wings, we believe that seamless communication is the key to enhancing experiences in the services industry. Our mission is to revolutionize in-person service communication by digitizing and streamlining the way businesses connect with their customers. With our real-time, bi-directional platform, businesses can effortlessly convey the up-to-the-minute status of their amenities, services, and offers. By replacing traditional analog methods with digital channels, we empower businesses to boost efficiency, increase customer satisfaction, and build stronger relationships.</p>
            <p>Join Wings today and discover a new era of efficient and personalized service communication.</p>

            <div style="padding: 20px 20px; background: #EEEEEE; border-radius: 1em">
              <h3 class="text-hint" style="margin-top:-10px"><q-icon class="text-hint" name="ion-alert" style="font-size: 33px"/> beta</h3>
              Our beta version of Wings is here to provide you with an early glimpse into the future of service communication. As we continue to refine and enhance our platform, your valuable feedback will shape the evolution of our product.
            </div>
          </div>
        </div>
      </q-modal-layout>
    </q-modal>

    <!--
      MODAL: Legal Terms
    -->
    <q-modal id="dialogLegalTerms" v-model="dialogLegalTermsShow" position="bottom" class="appLayer dialog-item"  enter-class="slideInUp animated800" leave-class="slideOutDown animated400">
      <q-modal-layout>
        <q-toolbar slot="header" inverted v-touch-pan.vertical.prevent.stop="modalAdapt" class="cursor-grab">
          <q-toolbar-title>{{ $t('DRAWER.ITEM.TERMS.L') }}</q-toolbar-title>
        </q-toolbar>
        <q-toolbar slot="header" inverted class="toolbar-overscroll-shadow">
          <q-card class="q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top">
            <q-card-main class="column justify-center full-height">
              <q-btn class="margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase" color="primary-light" text-color="primary" rounded @click="dialogLegalTermsShowClose">
                <img :src="'/statics/_demo/' + (anyDarkmode ? 'chevron.compact.down_white.svg': 'chevron.compact.down_primary.svg')" height="10">
              </q-btn>
            </q-card-main>
          </q-card>
        </q-toolbar>
        <div class="layout-padding items-center">
          <q-scroll-observable @scroll="toolbarShadowOnOverscroll"/>
          <div class="legal-wrapper text-system-brand" v-html="statics.staticsDocsLegalTerms"></div>
        </div>
      </q-modal-layout>
    </q-modal>

    <!--
      MODAL: Legal Privacy
    -->
    <q-modal id="dialogLegalPrivacy" v-model="dialogLegalPrivacyShow" position="bottom" class="appLayer dialog-item"  enter-class="slideInUp animated800" leave-class="slideOutDown animated400">
      <q-modal-layout>
        <q-toolbar slot="header" inverted v-touch-pan.vertical.prevent.stop="modalAdapt" class="cursor-grab">
          <q-toolbar-title>{{ $t('DRAWER.ITEM.PRIVACY.L') }}</q-toolbar-title>
        </q-toolbar>
        <q-toolbar slot="header" inverted class="toolbar-overscroll-shadow">
          <q-card class="q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top">
            <q-card-main class="column justify-center full-height">
              <q-btn class="margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase" color="primary-light" text-color="primary" rounded @click="dialogLegalPrivacyShowClose">
                <img :src="'/statics/_demo/' + (anyDarkmode ? 'chevron.compact.down_white.svg': 'chevron.compact.down_primary.svg')" height="10">
              </q-btn>
            </q-card-main>
          </q-card>
        </q-toolbar>
        <div class="layout-padding text-center items-center">
          <q-scroll-observable @scroll="toolbarShadowOnOverscroll"/>
          <div class="legal-wrapper text-system-brand" v-html="statics.staticsDocsLegalPrivacy"></div>
        </div>
      </q-modal-layout>
    </q-modal>

    <!--
      MODAL: Profile
    -->
    <q-modal id="dialogProfile" v-model="dialogProfileShow" position="bottom" class="appLayer dialog-item" enter-class="slideInUp animated800" leave-class="slideOutDown animated400">
      <q-modal-layout>
        <q-toolbar slot="header" inverted v-touch-pan.vertical.prevent.stop="modalAdapt" class="cursor-grab">
          <q-toolbar-title>{{ $t('PROFILE') }}</q-toolbar-title>
        </q-toolbar>
        <q-toolbar slot="header" inverted class="toolbar-overscroll-shadow">
          <q-card class="q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top">
            <q-card-main class="column justify-center full-height">
              <q-btn class="margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase" color="primary-light" text-color="primary" rounded @click.native="dialogProfileShow = false; soundPlay('tap')">
                <img :src="'/statics/_demo/' + (anyDarkmode ? 'chevron.compact.down_white.svg': 'chevron.compact.down_primary.svg')" height="10">
              </q-btn>
            </q-card-main>
          </q-card>
        </q-toolbar>
        <div v-if="authenticated" class="q-list-cards text-family-brand layout-padding no-padding-top text-center row justify-center">
          <q-scroll-observable @scroll="toolbarShadowOnOverscroll"/>

          <!-- Profile -->
          <q-list class="card text-system-brand text-weight-medium full-width on-top-lg" no-border link>
            <q-list-header class="text-weight-bold text-grey text-left">Profile</q-list-header>
            <q-item link tag="label" @click.native="dialogAccountShowNameEdit = true">
              <q-item-side class="text-center">
                <q-icon name="ion-contact" :color="anyDarkmode ? '' : 'blue-grey-10'" size="33px"/>
              </q-item-side>
              <q-item-main>
                <q-item-tile label class="capitalize">Name</q-item-tile>
              </q-item-main>
              <q-item-side right>
                <q-input
                  v-if="dialogAccountShowNameEdit"
                  class="text-weight-semibold text-black" style="width:180px"
                  autofocus
                  placeholder="Enter your first name"
                  @keyup.enter="accountUpdateName"
                  @blur="accountUpdateName"
                  @change="accountUpdateName"
                  :readonly="dialogAccountShowNameEditProcessing"
                  :disabled="dialogAccountShowNameEditProcessing"
                  :loading="dialogAccountShowNameEditProcessing"
                  v-model.trim="$store.state.app.io.name"/>
                <span v-else-if="$store.state.app.io.name" class="text-weight-semibold text-black">{{ $store.state.app.io.name }}</span>
                <span v-else class="text-weight-semibold text-attention">Not Set</span>
              </q-item-side>
            </q-item>
            <q-item v-if="authenticated" link tag="label">
              <q-item-side class="text-center">
                <q-icon name="ion-at" :color="anyDarkmode ? '' : 'blue-grey-10'" size="33px"/>
              </q-item-side>
              <q-item-main>
                <q-item-tile label class="capitalize">
                  Email
                </q-item-tile>
                <q-item-tile v-if="$store.state.app.io.email" sublabel :class="$store.state.app.io.email_verified ? 'text-educate' : 'text-attention'">
                  <template v-if="$store.state.app.io.email_verified">Verified</template>
                  <template v-else>
                    Not verified
                  </template>
                </q-item-tile>
              </q-item-main>
              <q-item-side right disabled disable class="disable">
                <span v-if="$store.state.app.io.email" class="text-weight-semibold text-black">{{ $store.state.app.io.email }}</span>
                <span v-else class="text-weight-semibold text-attention">Not Set</span>
              </q-item-side>
            </q-item>
          </q-list>

          <!-- Account -->
          <q-list class="card text-system-brand text-weight-medium full-width on-top-lg" no-border link>
            <q-list-header class="text-weight-bold text-grey text-left">Account</q-list-header>
            <!-- Wings ID -->
            <q-item v-if="authenticated" link tag="label" @click.native="wingsIDShowOpen">
              <q-item-side class="text-center">
                <q-icon name="ion-lock" :color="anyDarkmode ? '' : 'blue-grey-10'" size="33px"/>
              </q-item-side>
              <q-item-main>
                <q-item-tile label class="capitalize">Wings ID</q-item-tile>
                <q-item-tile sublabel class="text-purple-l2">Blockchain (ETH) verified</q-item-tile>
                <span v-if="$store.state.app.io.idx" class="block text-fixedwidth-brand font-size-70p text-tertiary">{{ $store.state.app.io.idx.split(':')[2] }}</span>
              </q-item-main>
            </q-item>
            <!-- Login / Logout -->
            <q-item v-if="authenticated" link tag="label" @click.native="dialogLogout()">
              <q-item-side class="text-center">
                <q-icon name="ion-log-out" :color="anyDarkmode ? '' : 'blue-grey-10'" size="33px"/>
              </q-item-side>
              <q-item-main>
                <q-item-tile label class="capitalize text-protect">Logout</q-item-tile>
                <q-item-tile sublabel>Sign out of your account</q-item-tile>
              </q-item-main>
            </q-item>
          </q-list>
        </div>
      </q-modal-layout>
    </q-modal>

    <!--
      MODAL: Settings
    -->
    <q-modal id="dialogAccount" v-model="dialogAccountShow" position="bottom" class="appLayer dialog-item" enter-class="slideInUp animated800" leave-class="slideOutDown animated400">
      <q-modal-layout>
        <q-toolbar slot="header" inverted v-touch-pan.vertical.prevent.stop="modalAdapt" class="cursor-grab">
          <q-toolbar-title>{{ $t('SETTINGS') }}</q-toolbar-title>
        </q-toolbar>
        <q-toolbar slot="header" inverted class="toolbar-overscroll-shadow">
          <q-card class="q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top">
            <q-card-main class="column justify-center full-height">
              <q-btn class="margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase" color="primary-light" text-color="primary" rounded @click.native="dialogAccountShow = false; soundPlay('tap')">
                <img :src="'/statics/_demo/' + (anyDarkmode ? 'chevron.compact.down_white.svg': 'chevron.compact.down_primary.svg')" height="10">
              </q-btn>
            </q-card-main>
          </q-card>
        </q-toolbar>

        <div class="q-list-cards text-family-brand layout-padding no-padding-top text-center row justify-center">
          <q-scroll-observable @scroll="toolbarShadowOnOverscroll"/>

          <!-- Experience -->
          <q-list class="card text-system-brand text-weight-medium full-width on-top-lg" no-border link>
            <q-list-header class="text-weight-bold text-grey text-left">Experience</q-list-header>
            <!-- effects -->
            <q-item link tag="label">
              <q-item-side class="text-center">
                <transition mode="out-in" appear enter-active-class="animated400 bounceIn" leave-active-class="animated400 bounceOut">
                  <q-icon v-if="settings_display_effects" key="settings-effects-on" name="ion-eye" :color="anyDarkmode ? '' : 'blue-grey-10'" size="33px"/>
                  <q-icon v-else key="settings-effects-off" name="ion-eye-off" :color="anyDarkmode ? '' : 'blue-grey-10'" size="33px"/>
                </transition>
              </q-item-side>
              <q-item-main>
                <q-item-tile label class="capitalize">Effects</q-item-tile>
                <q-item-tile sublabel>Shadows, blur, and motion effects</q-item-tile>
              </q-item-main>
              <q-item-side right>
                <q-toggle v-model="settings_display_effects" :dark="anyDarkmode"/>
              </q-item-side>
            </q-item>
            <!-- sound -->
            <q-item link tag="label">
              <q-item-side class="text-center">
                <transition mode="out-in" appear enter-active-class="animated400 bounceIn" leave-active-class="animated400 bounceOut">
                  <q-icon v-if="settings_sound" key="settings-sound-on" name="ion-volume-high" :color="anyDarkmode ? '' : 'blue-grey-10'" size="33px"/>
                  <q-icon v-else key="settings-sound-off" name="ion-volume-off" :color="anyDarkmode ? '' : 'blue-grey-10'" size="33px"/>
                </transition>
              </q-item-side>
              <q-item-main>
                <q-item-tile label class="capitalize">Sounds</q-item-tile>
                <q-item-tile sublabel>Immersive accent sounds</q-item-tile>
              </q-item-main>
              <q-item-side right>
                <q-toggle v-model="settings_sound" :dark="anyDarkmode"/>
              </q-item-side>
            </q-item>
            <!-- voice -->
            <q-item link tag="label" v-if="isDemoPage">
              <q-item-side class="text-center">
                <transition mode="out-in" appear enter-active-class="animated400 bounceIn" leave-active-class="animated400 bounceOut">
                  <q-icon v-if="settings_voice" key="settings-voice-on" name="ion-mic" :color="anyDarkmode ? '' : 'blue-grey-10'" size="33px"/>
                  <q-icon v-else key="settings-voice-off" name="ion-mic-off" :color="anyDarkmode ? '' : 'blue-grey-10'" size="33px"/>
                </transition>
              </q-item-side>
              <q-item-main>
                <q-item-tile label class="capitalize">Voice A.I. <q-chip class="q-chip-pro q-chip-denser" dense>BETA</q-chip></q-item-tile>
                <q-item-tile sublabel>Enable voice-driven interface</q-item-tile>
              </q-item-main>
              <q-item-side right>
                <q-toggle v-model="settings_voice" :dark="anyDarkmode"/>
              </q-item-side>
            </q-item>
          </q-list>
          <!-- App -->
          <q-list class="card text-system-brand text-weight-medium full-width on-top-lg" no-border link>
            <q-list-header class="text-weight-bold text-grey text-left">Application</q-list-header>
            <!-- Update -->
            <q-item link tag="label" @click.native="updateCheck">
              <q-item-side class="text-center">
                <q-icon name="ion-cloud-download" :color="anyDarkmode ? '' : 'blue-grey-10'" size="33px"/>
              </q-item-side>
              <q-item-main>
                <q-item-tile label class="capitalize">Version</q-item-tile>
                <q-item-tile sublabel>Download latest updates</q-item-tile>
              </q-item-main>
              <q-item-side right>
                <q-item-tile label class="text-black text-weight-semibold">
                  {{ $store.state.app.version }}
                  <q-chip v-if="!isProduction" class="q-chip-na q-chip-denser margin-xs-l" dense>dev</q-chip>
                  <q-chip v-if="isDemoPage" class="q-chip-pro q-chip-denser margin-xs-l" dense>AI</q-chip>
                  <q-chip v-if="$store.state.app.version_flag" class="q-chip-pro q-chip-denser margin-xs-l" dense>{{ $store.state.app.version_flag }}</q-chip>
                  <q-chip dense class="hidden"></q-chip>
                </q-item-tile>
                <q-item-tile sublabel>
                  {{ $store.state.app.version_date }}
                  <q-chip class="uppercase" square dense>{{ $store.state.app.version_build }}</q-chip>
                </q-item-tile>
              </q-item-side>
            </q-item>
          </q-list>
          <!-- Developer Mode -->
          <q-list v-if="!isProduction" class="card text-system-brand text-weight-medium full-width on-top-lg" no-border link>
            <q-list-header class="text-weight-bold text-grey text-left">Developer Debug</q-list-header>
            <!-- branding for Expo City Dubai -->
            <q-item link tag="label">
              <q-item-side class="text-center">
                <transition mode="out-in" appear enter-active-class="animated400 bounceIn" leave-active-class="animated400 bounceOut">
                  <img v-if="settings_branding" key="settings-branding-on" src="/statics/_demo/ecd-icon-on.svg" width="33" height="33">
                  <img v-else key="settings-branding-off" src="/statics/_demo/ecd-icon-off.svg" width="33" height="33">
                </transition>
              </q-item-side>
              <q-item-main>
                <q-item-tile label class="capitalize">Expo City Dubai</q-item-tile>
                <q-item-tile sublabel>Whitelabel to Expo City Dubai branding</q-item-tile>
              </q-item-main>
              <q-item-side right>
                <q-toggle v-model="settings_branding" :dark="anyDarkmode"/>
              </q-item-side>
            </q-item>
            <!-- Debug Chnanel Clients -->
            <q-item link tag="label">
              <q-item-side class="text-center">
                <transition mode="out-in" appear enter-active-class="animated400 bounceIn" leave-active-class="animated400 bounceOut">
                  <q-icon v-if="settings_dev_presense" key="settings-dev-presense-on" name="ion-code-working" :color="anyDarkmode ? '' : 'blue-grey-10'" size="33px"/>
                  <q-icon v-else key="settings-dev-presense-off" name="ion-code" :color="anyDarkmode ? '' : 'blue-grey-10'" size="33px"/>
                </transition>
              </q-item-side>
              <q-item-main>
                <q-item-tile label class="capitalize">Channel Presense</q-item-tile>
                <q-item-tile sublabel>Display Presense Module</q-item-tile>
              </q-item-main>
              <q-item-side right>
                <q-toggle v-model="settings_dev_presense" :dark="anyDarkmode"/>
              </q-item-side>
            </q-item>
          </q-list>
        </div>

      </q-modal-layout>
    </q-modal>

    <!--
      MODAL: Wallet
    -->
    <q-modal id="dialogWallet" v-model="dialogWalletShow" position="bottom" class="appLayer dialog-item" enter-class="slideInUp animated800" leave-class="slideOutDown animated400">
      <q-modal-layout>
        <q-toolbar slot="header" inverted v-touch-pan.vertical.prevent.stop="modalAdapt" class="cursor-grab">
          <q-toolbar-title>{{ $t('DRAWER.ITEM.WALLET.L') }}</q-toolbar-title>
        </q-toolbar>
        <q-toolbar slot="header" inverted class="toolbar-overscroll-shadow">
          <q-card class="q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top">
            <q-card-main class="column justify-center full-height">
              <q-btn class="margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase" color="primary-light" text-color="primary" rounded @click.native="dialogWalletShow = false">
                <img :src="'/statics/_demo/' + (anyDarkmode ? 'chevron.compact.down_white.svg': 'chevron.compact.down_primary.svg')" height="10">
              </q-btn>
            </q-card-main>
          </q-card>
        </q-toolbar>
        <div class="q-list-cards text-family-brand layout-padding no-padding-top text-center row justify-center">
          <q-scroll-observable @scroll="toolbarShadowOnOverscroll"/>

          <q-list class="card text-system-brand text-weight-medium full-width on-top-lg" no-border>
            <q-item>
              <q-item-main class="text-center font-size-180p text-weight-bold">
                <small class="text-grey">{{ user_details.currency || 'AED' }}</small>
                {{ user_details.funds / 100 | nformat('0,0.00') }}
                <q-btn icon="ion-refresh"
                  @click.native="fundsRefresh"
                  :loading="fundsRefreshing"
                  class="inline-block text-secondary spaced-width" dense
                />
              </q-item-main>
            </q-item>
            <q-item>
              <q-btn
                :loading="stripePaymentRequest !== null"
                @click.native="fundSelector"
                class="margin-auto-lr full-height text-system-brand text-weight-semibold uppercase" style="width: 80%"
                color="secondary"
                text-color="white"
              >
                <span class="font-size-100p text-system-brand">Add Funds to Wallet</span>
              </q-btn>
            </q-item>
            <q-item>
              <q-btn
                :loading="stripePaymentRequest !== null"
                :disabled="stripePaymentLink === null"
                @click="openURL(stripePaymentLink)"
                class="margin-auto-lr full-height text-system-brand text-weight-semibold uppercase" style="width: 80%"
                :color="stripePaymentLink === null ? 'grey' : 'primary'"
                text-color="white"
                icon-right="ion-open"
              >
                <span class="font-size-100p text-system-brand">
                  Pay {{ stripePaymentAmount / 100 | nformat('0,0.00') }} {{ user_details.currency || 'AED' }}
                </span>
              </q-btn>
            </q-item>

            <q-item-separator />
            <q-list-header class="text-weight-bold text-grey text-left">Localization</q-list-header>
            <!-- curreny -->
            <q-item tag="label" disabled disable>
              <q-item-side class="text-center">
                <q-icon name="ion-cash" :color="anyDarkmode ? '' : 'blue-grey-10'" size="33px"/>
              </q-item-side>
              <q-item-main>
                <q-item-tile label class="capitalize">Currency</q-item-tile>
                <q-item-tile sublabel>Unit for pricing</q-item-tile>
              </q-item-main>
              <q-item-side right>
                <q-item-tile label>{{ user_details.currency || 'AED' }}</q-item-tile>
                <q-item-tile sublabel class="capitalize">{{ getCurrencyName(user_details.currency) }}</q-item-tile>
              </q-item-side>
            </q-item>
          </q-list>
        </div>
      </q-modal-layout>
    </q-modal>

    <!--
      MODAL: Profiles
    -->
    <q-modal position="bottom" v-model="dialogProfilesShow" class="appLayer">
      <q-modal-layout>
        <q-toolbar slot="header" inverted>
          <q-toolbar-title>{{ $t('DRAWER.ITEM.PROFILES.L') }}</q-toolbar-title>
        </q-toolbar>
        <div class="layout-padding">
          <q-list class="list-card-options" no-border link :dark="anyDarkmode">
            <q-item tag="label" v-for="ecoId in ['homes', 'animals', 'foods', 'business']" :key="'profile-item-' + ecoId">
              <q-item-side class="list-card-options-option">
                <q-radio v-model="ecosystem_id" :val="ecoId" />
              </q-item-side>
              <q-item-main class="list-card-options-card">
                <q-item-tile label class="text-family-brand text-weight-medium">
                  <q-card :class="{ 'unselected': (ecoId !== ecosystem_id) }">
                    <q-card-title class="text-center">
                      <img width="38" class="dark-img-hero" :src="`statics/ecosystems/${ecoId}/icon.svg`" style="display:block;margin: 0 auto;margin-bottom:-8px">
                      {{ecoId}}
                    </q-card-title>
                  </q-card>
                </q-item-tile>
              </q-item-main>
              <q-item-main class="list-card-options-description">
                <q-item-tile label class="text-family-brand">Manage your {{ecoId}}</q-item-tile>
              </q-item-main>
            </q-item>
          </q-list>
        </div>
        <q-toolbar slot="footer" inverted>
          <q-btn size="lg" v-ripple push color="primary" @click="dialogProfilesShow = false" class="shadow-24 full-width">
            {{ $t('DONE') }}
          </q-btn>
        </q-toolbar>
      </q-modal-layout>
    </q-modal>

    <!--
      MODAL: Preferences
    -->
    <q-modal position="bottom" v-model="dialogPreferencesShow" class="appLayer dialog-item">
      <q-modal-layout>
        <q-toolbar slot="header" inverted>
          <q-toolbar-title>{{ $t('PERSONALIZE.LABEL_TT') }}</q-toolbar-title>
        </q-toolbar>
        <q-toolbar slot="header" inverted class="toolbar-overscroll-shadow">
          <q-card class="q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top">
            <q-card-main class="column justify-center full-height">
              <q-btn class="margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase" color="primary-light" text-color="primary" rounded @click.native="dialogPreferencesShow = false">
                <!-- {{ $t('DONE') }} -->
                <img :src="'/statics/_demo/' + (anyDarkmode ? 'chevron.compact.down_white.svg': 'chevron.compact.down_primary.svg')" height="10">
              </q-btn>
            </q-card-main>
          </q-card>
        </q-toolbar>
        <div class="layout-padding">
          <q-scroll-observable @scroll="toolbarShadowOnOverscroll"/>
          <q-select class="text-family-brand" no-border :stack-label="$t('LANGUAGE')" v-model="lang" :options="[
            { className: 'text-family-brand', label: 'English &nbsp; <span class=text-faded>— US</span>', value: 'enUS' },
            { className: 'text-family-brand', label: 'Español &nbsp; <span class=text-faded>— Spanish</span>', value: 'esUS' },
            { className: 'text-family-brand', label: '中文 &nbsp; <span class=text-faded>— Chinese Simplified</span>', value: 'zhCN' },
            { className: 'text-family-brand', label: 'عربي &nbsp; <span class=text-faded>— Arabic</span>', value: 'arSA' }
          ]" :dark="anyDarkmode"/>
          <q-list class="text-family-brand hidden" no-border link :dark="anyDarkmode">
            <br><p class="q-caption no-margin" style="color: #979797">{{ $t('APPEARANCE') }}</p>
            <q-item tag="label">
              <q-item-main>
                <q-item-tile label>{{ $t('APPEARANCE_DARKMODE') }}</q-item-tile>
                <q-item-tile sublabel lines="2">{{ $t('APPEARANCE_DARKMODE_INFO') }}</q-item-tile>
              </q-item-main>
              <q-item-side right>
                <q-toggle v-model="userDarkmode" :disable="deviceDarkmode"/>
              </q-item-side>
            </q-item>
          </q-list>
        </div>
      </q-modal-layout>
    </q-modal>

    <!--
      MODAL: Wings ID
    -->
    <q-modal id="dialogWingsID" class="dialog-item" v-model="dialogWingsIDShow" position="bottom" :content-css="{maxWidth: '400px'}" enter-class="slideInUp animated800" leave-class="slideOutDown animated400">
      <q-modal-layout>
        <q-toolbar slot="header" inverted>
          <q-toolbar-title>Wings ID</q-toolbar-title>
        </q-toolbar>
        <q-toolbar slot="header" inverted>
          <q-card class="q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top">
            <q-card-main class="column justify-center full-height">
              <q-btn class="margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase" color="primary-light" text-color="primary" rounded @click.native="dialogWingsIDShow = false; soundPlay('tap')">
                <img :src="'/statics/_demo/' + (anyDarkmode ? 'chevron.compact.down_white.svg': 'chevron.compact.down_primary.svg')" height="10">
              </q-btn>
            </q-card-main>
          </q-card>
        </q-toolbar>
        <div class="layout-padding">
          <p class="text-justify">
            Your <strong class="text-value">Wings ID</strong> account is secured by a unique Digital ID Ethereum Blockchain address shown below.
          </p>
          <p class="text-justify">
            This address acts like a virtual ID card that only you can access, protecting your account with secure and passwordless authentication method.
          </p>
          <p class="text-fixedwidth-brand text-center">
            <q-chip v-if="authenticated && $store.state.app.io.idx" dense class="q-chip-subinfo">{{ $store.state.app.io.idx.split(':')[2] }}</q-chip>
          </p>
          <p class="text-center subtitle">Powered by our partner <a href="https://magic.link" target="new">Magic</a>.</p>
          <img class="block margin-auto-lr" height="50" :src="'/statics/clients/magic.link/horiz/' + (anyDarkmode ? '2-Magic_Color_White.svg' : '1-Magic_Color_BLACK.svg')">
        </div>
      </q-modal-layout>
    </q-modal>

    <!--
      MODAL: Updates
    -->
    <q-modal v-model="dialogUpdatesShow" minimized no-esc-dismiss no-backdrop-dismiss>
      <q-modal-layout>
        <q-toolbar slot="header" inverted>
          <q-toolbar-title>{{ $t('UPDATES.TITLE_CHECK') }}</q-toolbar-title>
        </q-toolbar>
        <div class="layout-padding">
          <q-progress indeterminate color="secondary" stripe animate height="12px"/>
        </div>
      </q-modal-layout>
    </q-modal>
  </q-layout>
</template>

<script>
import { openURL, QSpinnerPuff, throttle, AppVisibility } from 'quasar'
import { axiosLIO } from 'plugins/axios'

// Sound { Howl, Howler }
import { Howl } from 'howler'

import AuthService from '../services/AuthService'
const auth = new AuthService()
const { login, logout, authenticated, authNotifier } = auth

// static documents
import staticsDocsLegalTerms from '../statics/docs/legal-terms.html.js'
import staticsDocsLegalPrivacy from '../statics/docs/legal-privacy.html.js'

// number format + Wings Dictionary
import nformat from 'vue-filter-number-format'
import Wings from '../services/wings-2.js'

// stripe
import { loadStripe } from '@stripe/stripe-js'

// Store
import store from './../store'

// Router
import router from './../router'

// Magic Link
import { magicLink } from 'plugins/magic'
// import { EventEmitter } from 'electron'
// import { emit } from 'npm'

export default {
  name: 'LayoutDefault',
  data () {
    authNotifier.on('authChange', authState => {
      this.authenticated = authState.authenticated
    })
    //
    // welcome
    //
    console.info('Welcome to Wings')
    console.info([
      '   _      _       _           _   _             __ _        ',
      '  | |    | |     | |         | | | |           / _| |       ',
      '  | | ___| |_ ___| |__  _   _| |_| |_ ___ _ __| |_| |_   _  (r)',
      "  | |/ _ \\ __/ __| '_ \\| | | | __| __/ _ \\ '__|  _| | | | | ",
      '  | |  __/ |_\\__ \\ |_) | |_| | |_| ||  __/ |  | | | | |_| | ',
      '  |_|\\___|\\__|___/_.__/ \\__,_|\\__|\\__\\___|_|  |_| |_|\\__, | ',
      '                                                      __/ | ',
      '                                                     |___/  '
    ].join('\n'))

    let ioSessionToken = localStorage.getItem('io_session_token')
    let ioAccessToken = localStorage.getItem('io_access_token')
    console.info('> ioSessionToken: ', ioSessionToken)
    console.info('> ioAccessToken: ', ioAccessToken)
    //
    // get a session data
    //
    axiosLIO.get('/session').then((response) => {
      console.log(response)
      // update session data
      this.$store.commit('app/updateIO', response.data.data)
      // overwrite tokens (if necessary)
      if (response.data.data.io_session_token && (response.data.data.io_session_token !== ioSessionToken)) {
        if (ioSessionToken !== response.data.data.io_session_token) {
          ioSessionToken = response.data.data.io_session_token
          this.$store.commit('app/updateIOSessionToken', ioSessionToken)
        }
      }
      if (response.data.data.io_access_token && (response.data.data.io_access_token !== ioAccessToken)) {
        ioAccessToken = response.data.data.io_access_token
        this.$store.commit('app/updateIOAccessToken', ioAccessToken)
      }
      // app is ready for use
      this.$store.commit('app/updateInit', true)
      console.info(`> Initialized`)
      console.info('> Authenticated: ', authenticated)

      // ping server to keep session alive (every 5s)
      // if (authenticated) {
      //   let authCheckPingTx = setInterval(
      //     throttle(() => {
      //       if (!authenticated) {
      //         console.info('> authCheckPingTx: END')
      //         return clearInterval(authCheckPingTx)
      //       }
      //       console.info('> authCheckPingTx: PING')
      //       console.info('> session: PING')
      //       axiosLIO.get('/session').then((r) => {
      //         let authCheck = r.data.data.authorized
      //         console.info('> session: OK: ', authCheck)
      //         if (!authCheck) {
      //           // logout
      //           clearInterval(authCheckPingTx)
      //           this.$router.push('/logout')
      //         }
      //       })
      //     }, 20000), 20000
      //   )
      // }
    })

    return {
      deviceDarkmode: false,
      drawerShow: false,
      dialogWalletShow: false,
      dialogProfilesShow: false,
      dialogPreferencesShow: false,
      dialogUpdatesShow: false,
      dialogWingsIDShow: false,
      dialogAboutShow: false,
      dialogLegalTermsShow: false,
      dialogLegalPrivacyShow: false,
      xdialogWingletShow: false,
      xdialogAccountShow: false,
      dialogProfileShow: false,
      dialogAccountShow: false,
      dialogAccountShowNameEdit: false,
      dialogAccountShowNameEditProcessing: false,
      dialogAccountShowEmailEdit: false,
      dialogPlansShow: false,
      dialogLoginShow: false,
      auth,
      authenticated,
      // login (email OTP)
      emailOTP: null,
      emailOTPProcessing: false,
      // statics
      statics: {
        staticsDocsLegalTerms,
        staticsDocsLegalPrivacy
      },
      // ui
      modalAdaptCache: {
        target: null,
        header: null,
        container: null
      },
      // stripe
      stripe: null,
      stripeElements: null,
      stripePaymentRequest: null,
      stripePayButton: null,
      stripePaymentLink: null,
      stripePaymentAmount: 0,
      // funds
      fundsRefreshing: null,
      // sounds
      sound: {
        theme: 'glasswing',
        themes: {
          glasswing: {
            ux: {
              tap: {},
              tap_disabled: {},
              sheet_up: {},
              sheet_mini_up: {},
              sheet_drop: {},
              sheet_grab: {},
              entry_actionsheet: {},
              entry_scrub: {},
              notification: {},
              pinging: {}
            }
          }
        }
      }
    }
  },
  filters: {
    nformat: nformat
  },
  /*
   * INIT
   */
  created () {
    // add global error capture to handle "chunk failures" in production
    window.onerror = (message, source, lineno, colno, error) => {
      // reload the app with the new URL if chunk error detected
      if (message.indexOf('Unexpected token') > 0) {
        // new version detected
        this.$q.dialog({
          title: this.$t('UPDATES.UPDATE'),
          message: 'The app must refresh to download the latest version.',
          ok: this.$t('UPDATES.UPDATE'),
          cancel: false
        }).then(() => {
          // reload app and purge cache
          this.$q.loading.show({
            spinner: QSpinnerPuff,
            spinnerSize: 250
          })
          setTimeout(() => window.location.reload(true), 200)
        }).catch(() => {
          // ignore
        })
      }
    }

    document.querySelector('#q-app > .q-loading-bar.top.bg-primary').hidden = true

    // new Magic Link
    // this.accountInit()

    // init settings
    this.soundInit()
    if (!this.settings_display_effects) {
      this.disableFilters()
    }
  },
  mounted () {
    let self = this
    // init deviceDarkmode event handler
    // this.renderDarkMode(this.userDarkmode)
    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      self.deviceDarkmode = true
    }
    this.renderDarkMode(self.deviceDarkmode)
    window.matchMedia('(prefers-color-scheme: dark)').addListener((e) => {
      console.log(':: MEDIA => deviceDarkmode')
      console.log(e.matches)
      self.deviceDarkmode = e.matches
    })
    // emit progress update
    this.$router.beforeEach((to, from, next) => {
      self.$emit('startAjaxBar')
      next()
    })
    this.initStripe()
    this.initHashTriggers()
    this.fundsRefresh()
    this.$emit('stopAjaxBar')
  },
  computed: {
    isDemoPage () {
      return this.$route.path.includes('-demo')
    },
    isProduction () {
      return window.location.href.startsWith(this.$store.state.app.production_domain) || true
    },
    isBusinessPage () {
      const regex = /^\/business\/.+/
      return regex.test(this.$route.path)
    },
    intentions: {
      get () {
        return this.$store.state.app.intentions
      },
      set (val) {
        return val
      }
    },
    user_details: {
      get () {
        return {
          id: this.$store.state.app.io.id,
          name: this.$store.state.app.io.name,
          phone: this.$store.state.app.io.phone,
          email: this.$store.state.app.io.email,
          email_verified: this.$store.state.app.io.email_verified,
          picture: this.$store.state.app.io.picture,
          funds: this.$store.state.app.io.funds,
          currency: this.$store.state.app.io.currency
        }
      }
    },
    ecosystem: {
      get () {
        return this.$store.state.app.io
      }
    },
    ecosystem_id: {
      get () {
        return this.$store.state.app.io.ecosystem_id
      },
      set (val) {
        this.$q.dialog({
          title: this.$t('PROFILES.SWITCH.LABEL_TT'),
          message: this.$t('PROFILES.SWITCH.MESSAGE', { profile: val }),
          ok: this.$t('YES'),
          cancel: this.$t('NO')
        }).then(() => {
          this.$q.loading.show({
            spinner: QSpinnerPuff,
            spinnerSize: 250
          })
          axiosLIO.post('/profile/set', {
            ecosystem_id: val
          }).then((res) => {
            // console.log(res)
            this.$store.commit('app/updateIO', res.data)
            setTimeout(() => {
              window.location = '/' + val
            }, 400)
          }).catch(err => {
            console.log(err)
            setTimeout(() => window.location.reload(true), 1900)
          })
        }).catch(() => {
          //
        })
      }
    },
    lang: {
      get () {
        return this.$store.state.app.lang
      },
      set (val) {
        this.$i18n.locale = val
        const el = document.documentElement, titleEl = document.getElementById('appTitle')
        let dir = (val === 'arSA') ? 'rtl' : 'ltr'
        el.setAttribute('dir', dir)
        el.setAttribute('lang', this.$t('lang'))
        titleEl.setAttribute('dir', dir)
        this.$store.commit('app/updateLanguage', val)
      }
    },
    userDarkmode: {
      get () {
        return this.$store.state.app.darkmode === true || this.$store.state.app.darkmode === 'true'
      },
      set (val) {
        this.$store.commit('app/updateDarkMode', val)
      }
    },
    anyDarkmode: {
      get () {
        return false // this.deviceDarkmode
      }
    },
    settings_dev_presense: {
      get () {
        return this.$store.state.app.preferences.dev_presense.enabled
      },
      set (val) {
        this.$store.state.app.preferences.dev_presense.enabled = val
        this.$store.commit('app/updatePreferencesDevPresenseState', val)
        this.soundPlay('sheet_drop')
      }
    },
    settings_branding: {
      get () {
        return this.$store.state.app.preferences.branding.enabled
      },
      set (val) {
        this.$store.state.app.preferences.branding.enabled = val
        this.$store.commit('app/updatePreferencesBrandingState', val)
        this.soundPlay('sheet_drop')
      }
    },
    settings_voice: {
      get () {
        return this.$store.state.app.preferences.voice.enabled
      },
      set (val) {
        this.$store.state.app.preferences.voice.enabled = val
        this.$store.commit('app/updatePreferencesVoiceState', val)
        this.soundPlay('sheet_drop')
      }
    },
    settings_sound: {
      get () {
        return this.$store.state.app.preferences.sound.enabled
      },
      set (val) {
        this.$store.state.app.preferences.sound.enabled = val
        this.$store.commit('app/updatePreferencesSoundState', val)
        this.soundPlay('sheet_drop')
      }
    },
    settings_display_effects: {
      get () {
        return this.$store.state.app.preferences.display.effects.enabled
      },
      set (val) {
        this.$store.state.app.preferences.display.effects.enabled = val
        this.$store.commit('app/updatePreferencesDisplayEffectsState', val)
        this.soundPlay('sheet_drop')
        if (val) {
          this.enableFilters()
          // this.soundPlay('sheet_drop')
        } else {
          this.disableFilters()
          // this.soundPlay('entry_scrub')
        }
      }
    }
  },
  watch: {
    deviceDarkmode (val) {
      this.renderDarkMode(val)
    },
    userDarkmode (val) {
      this.renderDarkMode(val)
    },
    '$q.appVisible' (val) {
      if (val) {
        // trigger related modules
        if (this.dialogWalletShow) {
          this.fundsRefresh()
        }
      }
    }
  },
  methods: {
    login,
    logout,
    openURL,
    async fundsRefresh () {
      this.fundsRefreshing = true
      const response = await axiosLIO.post('/funds')
      this.$store.commit('app/updateFunds', response.data.data.funds)
      this.$store.commit('app/updateCurrency', response.data.data.currency)
      this.$store.state.app.io.funds = response.data.data.funds
      this.$store.state.app.io.currency = response.data.data.currency
      this.fundsRefreshing = null
    },
    fundSelector () {
      this.soundPlay('entry_actionsheet')
      this.$q.actionSheet({
        title: 'Add Funds (AED)',
        actions: [{
          label: '20.00',
          avatar: '/statics/_demo/collection.add.svg',
          value: 20.00 * 100
        }, {
          label: '40.00',
          avatar: '/statics/_demo/collection.add.svg',
          value: 40.00 * 100
        }, {
          label: '80.00',
          avatar: '/statics/_demo/collection.add.svg',
          value: 80.00 * 100
        }, {
          label: '100.00',
          avatar: '/statics/_demo/collection.add.svg',
          value: 100.00 * 100
        }, {
          label: '200.00',
          avatar: '/statics/_demo/collection.add.svg',
          value: 200.00 * 100
        }, {}, {
          label: 'Clear',
          avatar: '/statics/_demo/clear_tertiary.svg',
          value: 'clear'
        }]
      }).then(action => {
        if (action.value) {
          // initiate payment
          this.initiatePayment(action.value)
        }
      }).catch(() => {
        this.soundPlay('tap')
      })
    },
    async initiatePayment (amount) {
      if (amount === 'clear') {
        // clear
        this.stripePaymentLink = null
        this.stripePaymentAmount = null
        this.stripePaymentRequest = null
        return
      }
      this.stripePaymentLink = null
      this.stripePaymentRequest = true
      this.stripePaymentAmount = amount

      // call API to create a PaymentIntent
      const response = await axiosLIO.post(`/pay/wallet/intent/${amount}/aed`, {}, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      const checkoutUrl = response.data.data.url
      this.stripePaymentLink = checkoutUrl
      this.stripePaymentRequest = null
    },
    async initStripe () {
      this.stripe = await loadStripe('pk_test_lAqu8QJ68AU5TiaOIwNhODHQ')
      this.stripeElements = this.stripe.elements()
    },
    initHashTriggers (hash) {
      let _hash = hash || document.location.hash
      if (_hash && _hash.length) {
        if (_hash === '#wallet') {
          // open wallet
          setTimeout(() => {
            this.drawerShow = true
            this.dialogWallet()
          }, 800)
        }
      }
    },
    appEmit (eventId) {
      this.$eventBus.$emit(eventId)
      console.log(`::appEmit: >> ${eventId}`)
    },
    onStartAjaxBar () {
      this.$emit('startAjaxBar')
    },
    onStopAjaxBar () {
      this.$emit('stopAjaxBar')
    },
    getStarted () {
      this.$router.push('/login')
    },
    accountDialogTrigger () {
      this.soundPlay('sheet_up')
      this.dialogAccountShow = true
      this.toolbarShadowOnOverscrollClear()
    },
    profileDialogTrigger () {
      this.soundPlay('sheet_up')
      this.dialogProfileShow = true
      this.toolbarShadowOnOverscrollClear()
    },
    accountUpdateName (el) {
      console.log(el)
      this.dialogAccountShowNameEditProcessing = true
      // update the name
      let name = this.$store.state.app.io.name
      axiosLIO.post('/profile/name', { name }).then((res) => {
        console.log(res)
        this.$store.commit('app/updateName', name)
        setTimeout(() => {
          this.dialogAccountShowNameEdit = false
          this.dialogAccountShowNameEditProcessing = false
        }, 1000)
      }).catch(err => {
        // error
        console.log(err)
      })
    },
    randomString (length) {
      let charset = '0123456789ABCDEFGHIJKLMNOPQRSTUVXYZabcdefghijklmnopqrstuvwxyz-._~', result = []
      while (length > 0) {
        window.crypto.getRandomValues(new Uint8Array(16)).forEach((c) => {
          if (!length) return
          if (c < charset.length) {
            result.push(charset[c])
            length--
          }
        })
      }
      return result.join('')
    },
    scheduleRenewal () {
      let expiresAt = JSON.parse(localStorage.getItem('expires_at'))
      let delay = expiresAt - Date.now()
      if (delay > 0) {
        this.tokenRenewalTimeout = setTimeout(() => {
          this.renewToken()
        }, delay)
      }
    },
    setSession (authResult) {
      // Set the time that the access token will expire at
      // console.log(`auth expires in: ${authResult.expiresIn}`)
      const now = new Date()
      now.setFullYear(now.getFullYear() + 1)
      const timestampOneYearFromNow = now.getTime()
      let expiresAt = JSON.stringify(timestampOneYearFromNow)
      localStorage.setItem('io_access_token', authResult.accessToken)
      localStorage.setItem('id_token', authResult.idToken)
      localStorage.setItem('expires_at', expiresAt)
      // this.authenticated = true
      // this.authNotifier.emit('authChange', { authenticated: true })
      this.scheduleRenewal()
    },
    setIO (ioResult) {
      store.commit('app/updateIO', ioResult)
    },
    loginEmailOTPToken (idToken) {
      // this.user.processed = false

      axiosLIO.post(`/acquire`, {
        id_token: idToken
      }).then((response) => {
        console.log('======================')
        console.log(response)
        if (response.status === 200) {
          var d = response.data.data
          console.log('======================')
          console.log(d)
          try {
            // check if LIO authentication is valid
            if (d.auth_verify.valid) {
              // store io data
              this.setIO(d.user)
            }
          } catch (e) {}
        }
        var tkn = this.randomString(20)
        console.log('REDIRECT --- ' + tkn)
        setTimeout(() => {
          // check if LTS is set
          var lts = localStorage.getItem('lts')
          if (lts) {
            window.location = '/channel/' + lts + '/?r=' + tkn
            // router.push()
          } else {
            window.location = '/?r=' + tkn
            // router.replace('/?r=' + tkn)
          }
        }, 400)
      })
    },
    async loginEmailOTP () {
      let emailAddress = this.emailOTP
      this.emailOTPProcessing = true
      try {
        const idToken = await magicLink.auth.loginWithEmailOTP({ email: emailAddress, showUI: true })
        console.log(`DID Token: ${idToken}`)

        // backend authentication
        this.setSession({
          expiresIn: 99999,
          accessToken: this.randomString(20),
          idToken: idToken
        })

        this.loginEmailOTPToken(idToken)
        // const userInfo = await magic.user.getInfo();
        // console.log(`UserInfo: ${userInfo}`);
      } catch (e) {
        // Handle errors if required!
        console.log(e)
        console.log(e.description)
        this.emailOTPProcessing = false
        this.$q.dialog({
          title: 'Invalid Email',
          message: 'Check your email address and insure it is valid.'
        })
      }
    },
    dialogLoginShowOpen () {
      this.soundPlay('sheet_up')
      this.dialogLoginShow = true
      this.toolbarShadowOnOverscrollClear()
    },
    dialogLoginShowClose () {
      this.soundPlay('sheet_drop')
      this.dialogLoginShow = false
    },
    dialogAboutShowOpen () {
      this.soundPlay('sheet_up')
      this.dialogAboutShow = true
      this.toolbarShadowOnOverscrollClear()
    },
    dialogAboutShowClose () {
      this.soundPlay('sheet_drop')
      this.dialogAboutShow = false
    },
    dialogLegalTermsShowOpen () {
      this.soundPlay('sheet_up')
      this.dialogLegalTermsShow = true
      this.toolbarShadowOnOverscrollClear()
    },
    dialogLegalTermsShowClose () {
      this.soundPlay('sheet_drop')
      this.dialogLegalTermsShow = false
    },
    dialogLegalPrivacyShowOpen () {
      this.soundPlay('sheet_up')
      this.dialogLegalPrivacyShow = true
      this.toolbarShadowOnOverscrollClear()
    },
    dialogLegalPrivacyShowClose () {
      this.soundPlay('sheet_drop')
      this.dialogLegalPrivacyShow = false
    },
    wingletDialogTrigger () {
      // toggle
      this.xdialogWingletShow = !this.xdialogWingletShow
    },
    resetScroll (el, done) {
      document.documentElement.scrollTop = 0
      document.body.scrollTop = 0
      done()
    },
    drawerTriggerShow () {
      this.drawerShow = true
      this.soundPlay('sheet_up')
    },
    drawerTriggerHide () {
      this.drawerShow = false
      this.soundPlay('sheet_drop')
    },
    toggleDrawer () {
      this.soundPlay('sheet_up')
      this.drawerShow = !this.drawerShow
    },
    dialogWallet () {
      // this.drawerShow = false
      this.dialogWalletShow = !this.dialogWalletShow
      if (this.dialogWalletShow) {
        this.fundsRefresh()
      }
    },
    dialogProfiles () {
      this.drawerShow = false
      this.dialogProfilesShow = !this.dialogProfilesShow
    },
    dialogPreferences () {
      this.drawerShow = false
      this.dialogPreferencesShow = !this.dialogPreferencesShow
    },
    goHome () {
      this.$router.push({ path: '/' })
    },
    goBack (path) {
      this.$router.push(path)
    },
    viewChannel () {
      this.$q.dialog({
        title: 'Enter Channel ID',
        message: '',
        ok: this.$t('GO'),
        cancel: this.$t('CANCEL'),
        prompt: {
          type: 'text'
        }
      }).then(data => {
        const pattern = /^[\p{L}0-9_]+-[a-f0-9]{32}$/u
        let channelID = `${data}`.trim()
        if (pattern.test(channelID)) {
          let channelPath = '/channel/' + channelID
          this.$router.push(channelPath)
        } else {
          this.$q.notify({
            message: 'Invalid Channel ID',
            detail: 'Example: "caff_cafe-e664eb2afc1e9cccf0f13c05444b326d"',
            color: 'white',
            textColor: 'value',
            position: 'top',
            timeout: 4000
          })
        }
      }).catch(() => {
        // ignore
      })
    },
    dialogLogout () {
      this.$q.dialog({
        title: this.$t('LOGOUT'),
        message: this.$t('LOGOUT_MESSAGE'),
        ok: this.$t('YES'),
        cancel: this.$t('NO')
      }).then(() => {
        this.drawerShow = false
        this.dialogAccountShow = false
        setTimeout(() => {
          this.$router.push('/logout')
        }, 500)
      }).catch(() => {
        //
      })
    },
    renderDarkMode (val) {
      console.log(':::::: renderDarkMode: ', val)
      val = val === 'true' || val === true
      document.documentElement.classList[val ? 'add' : 'remove']('dark')
    },
    wingsIDShowOpen () {
      this.dialogWingsIDShow = true
    },
    updateCheck () {
      // check for a new version
      this.dialogUpdatesShow = true
      setTimeout(() => {
        let updateExists = true
        this.dialogUpdatesShow = false
        if (updateExists) {
          // new version detected
          this.$q.dialog({
            title: this.$t('UPDATES.UPDATE'),
            message: 'Would you like to refresh the app to download the latest version?',
            ok: this.$t('UPDATES.UPDATE'),
            cancel: this.$t('UPDATES.NOT_NOW')
          }).then(() => {
            // reload app and purge cache
            this.$q.loading.show({
              spinner: QSpinnerPuff,
              spinnerSize: 250
            })
            setTimeout(() => window.location.reload(true), 1800)
          }).catch(() => {
            // ignore
          })
        } else {
          // no updates
          this.$q.notify({
            message: this.$t('UPDATES.TITLE_NO_UPDATES'),
            color: 'white',
            textColor: 'value',
            position: 'top',
            timeout: 2000
          })
        }
      }, 800)
    },
    toolbarShadowOnOverscrollTarget () {
      let modalTarget = null
      document.querySelectorAll('.modal').forEach((o, i) => {
        if (o.clientHeight !== 0) modalTarget = o
      })
      return modalTarget
    },
    toolbarShadowOnOverscrollClear (timeout = 10) {
      setTimeout(() => {
        try {
          let modalTarget = this.toolbarShadowOnOverscrollTarget()
          if (modalTarget) {
            modalTarget.querySelector('.toolbar-overscroll-shadow').classList.remove('toolbar-overscroll-shadow-show')
          }
        } catch (e) {
          console.log(e)
        }
      }, timeout)
    },
    toolbarShadowOnOverscroll (scroll) {
      let modalTarget = this.toolbarShadowOnOverscrollTarget()
      if (modalTarget) {
        if (scroll.direction === 'down' && scroll.position >= 1) {
          modalTarget.querySelector('.toolbar-overscroll-shadow').classList.add('toolbar-overscroll-shadow-show')
        } else if (scroll.direction === 'up' && scroll.position <= 10) {
          modalTarget.querySelector('.toolbar-overscroll-shadow').classList.remove('toolbar-overscroll-shadow-show')
        }
      }
    },
    soundInit () {
      // load sounds
      let theme = this.sound.themes[this.sound.theme]
      Object.keys(theme.ux).forEach((uxi) => {
        console.log(':: Sound load: ', uxi)
        this.sound.themes[this.sound.theme].ux[uxi] = new Howl({ src: [`/statics/sound/${uxi}.mp3`] })
      })
      this.sound.loaded = true
    },
    soundPlay (uxi) {
      // async-run the sound (if enabled)
      if (this.settings_sound) {
        setTimeout(() => {
          this.sound.themes[this.sound.theme].ux[uxi].play()
        }, 1)
      }
    },
    soundLoop (uxi, loop) {
      this.sound.themes[this.sound.theme].ux[uxi].loop(!!loop)
    },
    soundStop (uxi) {
      this.sound.themes[this.sound.theme].ux[uxi].stop()
    },
    soundFade (uxi, from, to) {
      this.sound.themes[this.sound.theme].ux[uxi].fade(from, to, 400)
    },
    disableSounds () {
      this.settings_sound = false
    },
    enableSounds () {
      this.settings_sound = true
    },
    disableFilters () {
      document.querySelector('html').classList.add('no-filters')
    },
    enableFilters () {
      document.querySelector('html').classList.remove('no-filters')
    },
    getCurrencyName (currencyLabel) {
      for (var cur in Wings.cost.currency.list) {
        if (Wings.cost.currency.list[cur].label === currencyLabel) {
          return Wings.cost.currency.list[cur].name
        }
      }
      return 'United Arab Emirates'
    },
    changeCurrency () {
      let imgCheckmark = '<img class="q-actionsheet-indicator q-actionsheet-indicator-dense float-right" src="/statics/_demo/checkmark_green.svg"/>'
      let actions = []
      for (var cur in Wings.cost.currency.list) {
        let currencyOption = {
          value: cur,
          label: Wings.cost.currency.list[cur].label + '<br><span>' + Wings.cost.currency.list[cur].name + '</span>'
        }
        if (Wings.cost.currency.list[cur].label === this.user_details.currency) {
          currencyOption.label += imgCheckmark
        }
        actions.push(currencyOption)
      }
      this.soundPlay('entry_actionsheet')
      this.$q.actionSheet({
        title: 'Currency Selection',
        actions
      }).then(action => {
        // update user currency
        alert(Wings.cost.currency.list[action.value])
      }).catch(() => {
        this.soundPlay('tap')
      })
    },
    modalAdapt (obj) {
      let modalTarget = this.toolbarShadowOnOverscrollTarget()
      let modalTargetHeader = modalTarget.querySelector('.q-toolbar')
      let modalTargetContainer = modalTarget.querySelector('.modal-content')

      let modalStyleDefault = 'border-bottom-left-radius: 0px; border-bottom-right-radius: 0px; transition: all 0.2s ease-in-out !important;'
      // header adaption
      if (modalTarget) {
        if (obj.isFirst) {
          console.log('grab: started')
          modalTargetHeader.classList.remove('cursor-grab')
          modalTargetHeader.classList.add('cursor-grabbing')
          let offsetHeight = modalTargetContainer.offsetHeight
          let finalHeight = offsetHeight - obj.delta.y
          modalTargetContainer.setAttribute('style', `max-height: ${finalHeight}px; transform: scale(1.04)`)
          modalTarget.classList.remove('dialog-grow')
          modalTarget.classList.remove('dialog-grow-full')
          this.soundPlay('sheet_grab')
        } else if (obj.isFinal) {
          console.log('grab: ended')
          modalTargetHeader.classList.remove('cursor-grabbing')
          modalTargetHeader.classList.add('cursor-grab')
          let offsetHeight = modalTargetContainer.offsetHeight
          let finalHeight = offsetHeight - obj.delta.y // transform: scale(1);
          // adjust position (if needed)
          let availableHeight = window.innerHeight
          if (availableHeight - finalHeight <= 50) {
            finalHeight = availableHeight
            modalTarget.classList.add('dialog-grow')
            modalTarget.classList.add('dialog-grow-full')
          } else if (finalHeight <= 200) {
            finalHeight = 200
          // } else if (availableHeight - finalHeight >= 61) {
          //   finalHeight = availableHeight - 61
          }
          modalTargetContainer.setAttribute('style', `max-height: ${finalHeight}px; ${modalStyleDefault}`)
          this.soundPlay('sheet_drop')
        } else {
          console.log('grab: grabbing...')
          let offsetHeight = modalTargetContainer.offsetHeight
          let finalHeight = offsetHeight - obj.delta.y // transform: scale(1.04);
          modalTargetContainer.setAttribute('style', `max-height: ${finalHeight}px; transition: none;
          transform: scale(1.04);
          box-shadow: 0 -11px 15px -7px rgba(0,0,0,0.2), 0 -24px 38px 3px rgba(0,0,0,0.14), 0 -9px 46px 8px rgba(0,0,0,0.12) !important`)
        }
      }
    }
  }
}
</script>

<style scoped lang="stylus">
#logo-wordmark
  display block
  margin auto

.list-card-options
  .list-card-options-card
    flex inherit
    min-width auto
    max-width 160px
    margin-right 20px
    .q-card
      margin 0
      max-width 110px
      &.unselected
        box-shadow none !important
        transform scale(0.9)
  .list-card-options-description
    flex inherit
    min-width auto

@media only screen and (max-device-width: 425px)
  .list-card-options
    .list-card-options-card
      margin 0

#dialogItem, .dialog-item
  .q-modal-layout
    background-repeat no-repeat
    background-position 130% bottom
    background-color rgba(255, 255, 255, 0.96)
    background-blend-mode color
    background-size 50%
  .q-option-inner
    i
      font-size 160%

</style>

<style lang="stylus">
.content-wrapper, .legal-wrapper
  padding 20px
  h1
    font-size 2em
    font-weight bold
  h2
    font-size 1.4em
    font-weight bold
    margin-bottom 4px
  h3
    font-size 1.2em
    font-weight bold
    font-family ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol"
  p.mini-heading
    font-size .8em
    color $tertiary
    text-transform uppercase
  .legal
    font-size .8em

.legal-wrapper
  text-align justify

.content-wrapper
  text-align left
  line-height 1.2em
</style>
