export default `
<h1>Terms of Use</h1>
<p class="mini-heading">Effective date / FEB 1, 2023<br/></p>

<div class="html-embed-3 legal w-embed">
    <p>letsbutterfly, Inc. (“us,” “we,” or “our”) operates the letsbutterfly.com website (the “Site”). The term "you" or
        "You" shall refer to any person or entity who views, uses, accesses, or browses any content or material on our
        Site. Your use of the Site, including any materials provided to you by the Site are subject to the following
        terms and conditions (“Terms of Service”) which create a binding contract between you and us. By accessing or
        using the Site you agree to follow and be bound by these Terms of Service. <strong>IF YOU DO NOT ACCEPT ALL OF
            THESE TERMS OF SERVICE, DO NOT USE THE SITE.</strong></p>

    <h2><strong>A.</strong> DESCRIPTION OF OUR SITE</h2>
    <p>Our Site endeavors to provide you with information, and in some instances, real-time information, regarding
        seating availability and wait times (if applicable), as well as services, (such as food, beverage, and delivery
        options) offered at participating businesses (the “Businesses”). We provide information only – we do not sell
        services (such as food, beverages or provide delivery site, nor do we take orders for services (such as
        painting, food or beverages, whether provided by the Businesses or any other third party. We do not recommend
        individual Businesses, investigate, or verify any representations made by Businesses with regard to services or
        Site, nor do we have any control over or responsibility for the quality of any their products or Site, or any
        Businesses’s compliance with applicable laws or local health or other regulations.</p>
    <p>You are responsible for reviewing and confirming any information provided by the Businesses on the Site and
        contacting the Businesses directly with regard to seating availability and reservations, or with regard to the
        reliability and quality of the Businesses and the Businesses’ compliance with applicable laws and regulations.
        Appointment availability, or the availability of services may change immediately and without notice to you. We
        do not in any way guaranty the availability or quality of any Business or any services, or compliance by any
        Business with applicable laws or regulations.</p>
    <p><strong>If you have special requirements with regard to service preparation (such as in foodservice with
            vegetarian, vegan, organic, kosher or other standards) or if you require your chosen Business to meet
            allergen-specific standards (such as nut, gluten, or lactose-free preparation), you are advised to contact
            the Business directly to inquire as to the availability of service preparations meeting your required
            standards. We make no representation as to the service preparation standards in use at any Business nor do
            we guarantee that your service will be provided as requested.</strong></p>
    <p>You and any participating Business are solely responsible for complying with all applicable laws governing the
        sale, delivery, and consumption of alcoholic beverages. All sales of alcohol are between you and each Business,
        and we are not a party to any such transaction.</p>

    <h2><strong>B.</strong> USE OF THE SITE.</h2>
    <p>Any information or materials made available from our Site, are our copyrighted work or the copyrighted work of
        our licensors including, but not limited to all text, graphics, photographs, trademarks, logos and the design,
        structure, and “look and feel” of the Site. No such materials may be copied, reproduced, sold, republished,
        transmitted, displayed, reposted or otherwise distributed for public or commercial purposes. You represent and
        warrant that your use of the Site is solely for your personal use.</p>

    <h2><strong>C.</strong> DATA COLLECTION AND PERSONAL DATA.</h2>
    <p>The Site may include features that: (a) gather Site activation, usage and/or environment information; (b)
        identify trends and/or bugs; (c) collect usage statistics, and/or (d) track other data related to your use of
        the Site, as further described in the current version of our Privacy Policy available at <a
            href="https://wings.business/privacy">wings.business/privacy</a>. By your acceptance of these Terms of
        Service or your use of the Site, you authorize the collection, use and disclosure of this data for the purposes
        provided in these Terms of Service and the Privacy Policy.</p>

    <h2><strong>D.</strong> CONTENT.</h2>
    <p>For purposes of these Terms of Service, the term “Content” includes, without limitation, information, data, text,
        photographs, videos, audio clips, graphics, and interactive features generated, provided, or otherwise made
        accessible on or through the Site.</p>
    <p>Subject to these Terms of Service, we grant you a worldwide, non-exclusive, non-sublicensable and
        non-transferable license to use (i.e., to download and display locally) Content solely for purposes of using the
        Site. Use, reproduction, modification, distribution or storage of any Content other than for purposes of using
        the Site are expressly prohibited without prior written permission from us. You may not sell, license, rent, or
        otherwise use or exploit any Content for commercial use or in any way that violates any third party right.</p>

    <h2><strong>E.</strong> RULES OF CONDUCT.</h2>
    <p>As a condition of use, you promise not to use the Site for any purpose prohibited by these Terms of Service. You
        are responsible for all of your activity in connection with your use of the Site.</p>
    <p>You agree not to: (i) take any action that imposes or may impose (as determined by us in our sole discretion) an
        unreasonable or disproportionately large load on our (or our third party providers’) infrastructure; (ii)
        interfere or attempt to interfere with the proper working of the Site or any activities conducted on the Site;
        (iii) bypass, circumvent or attempt to bypass or circumvent any measures we may use to prevent or restrict
        access to the Site (or other accounts, computer systems or networks connected to the Site); (iv) run any form of
        auto-responder or “spam” on the Site; (v) use manual or automated software, devices, or other processes to
        “crawl” or “spider” any page of the Site; (vi) harvest or scrape any Content from the Site; or (vii) otherwise
        take any action in violation of our guidelines and policies.</p>
    <p>You agree not to (directly or indirectly): (i) decipher, decompile, disassemble, reverse engineer or otherwise
        attempt to derive any source code or underlying ideas or algorithms of any part of the Site, except to the
        limited extent applicable laws specifically prohibit such restriction, (ii) modify, translate, or otherwise
        create derivative works of any part of the Site, or (iii) copy, rent, lease, distribute, or otherwise transfer
        any of the rights that you receive hereunder. You agree to abide by all applicable local, state, national and
        international laws and regulations.</p>
    <p>We reserve the right to access, read, preserve, and disclose any information we reasonably believe is necessary
        to: (i) satisfy any applicable law, regulation, legal process or governmental request; (ii) enforce these Terms
        of Service, including investigation of potential violations hereof; (iii) detect, prevent, or otherwise address
        fraud, security or technical issues; (iv) respond to user support requests; or (v) protect the rights, property
        or safety of us, our users and the public.</p>

    <h2><strong>F.</strong> THIRD PARTY SITES.</h2>
    <p>Links to third-party web sites or content on our Site are provided solely as a convenience to you. If you use
        these links, you will leave our Site. We have not reviewed these third-party sites and do not control and are
        not responsible for any of these sites or their content. We do not endorse or make any representations about
        them, or any information, products, or materials found there, or any results that may be obtained from using
        them.</p>
    <p>If you decide to access any of the third-party sites linked to this Site, you do this entirely at your own risk.
    </p>

    <h2><strong>G.</strong> TERMINATION.</h2>
    <p>We may terminate your access to all or any part of the Site at any time, with or without cause, with or without
        notice, effective immediately. All provisions of these Terms of Service which by their nature should survive
        termination shall survive termination, including, without limitation, ownership provisions, warranty
        disclaimers, indemnity and limitations of liability.</p>

    <h2><strong>H.</strong> WARRANTY DISCLAIMER.</h2>
    <p>TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE SITE AND CONTENT ARE PROVIDED “AS IS”, “AS AVAILABLE” AND
        WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF
        TITLE, NON-INFRINGEMENT, MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE, AND ANY WARRANTIES IMPLIED BY ANY
        COURSE OF PERFORMANCE OR USAGE OF TRADE, ALL OF WHICH ARE EXPRESSLY DISCLAIMED. WE, AND OUR DIRECTORS,
        EMPLOYEES, AGENTS, SUPPLIERS, PARTNERS AND CONTENT PROVIDERS DO NOT WARRANT THAT: (I) THE SITE WILL BE SECURE OR
        AVAILABLE AT ANY PARTICULAR TIME OR LOCATION; (II) ANY DEFECTS OR ERRORS WILL BE CORRECTED; (III) ANY CONTENT
        AVAILABLE AT OR THROUGH THE SITE IS FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS; OR (IV) THE RESULTS OF USING
        THE SITE WILL MEET YOUR REQUIREMENTS. YOUR USE OF THE SITE IS SOLELY AT YOUR OWN RISK. APPLICABLE LAW MAY NOT
        ALLOW THE EXCLUSION OR LIMITATION OF IMPLIED WARRANTIES, SO THE ABOVE LIMITATIONS OR EXCLUSIONS MAY NOT APPLY TO
        YOU.</p>
</div>
<div class="html-embed-3 legal w-embed">
    <h2><strong>I.</strong> INDEMNIFICATION.</h2>
    <p>You agree to defend, indemnify, and hold us, our affiliates and each of our and their respective employees,
        contractors, directors, suppliers and representatives harmless from all liabilities, claims, and expenses,
        including reasonable attorneys’ fees, that arise from or relate to your use or misuse of, or access to, the
        Site, Content, or otherwise from your violation of these Terms of Service, negligence, fraud, or infringement by
        you, or any third party using your identity on the Site, of any intellectual property or other right of any
        person or entity, including your use of the Site to provide a link to another website or to upload content or
        other information to the Site. We reserve the right to assume the exclusive defense and control of any matter
        otherwise subject to indemnification by you, in which event you will assist and cooperate with us in asserting
        any available defenses.</p>

    <h2><strong>J.</strong> LIMITATION OF LIABILITY.</h2>
    <p>TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL WE, NOR OUR DIRECTORS, EMPLOYEES, AGENTS,
        PARTNERS, SUPPLIERS OR CONTENT PROVIDERS, BE LIABLE UNDER CONTRACT, TORT, STRICT LIABILITY, NEGLIGENCE OR ANY
        OTHER LEGAL OR EQUITABLE THEORY WITH RESPECT TO THE SITE: (I) FOR ANY LOST PROFITS, DATA LOSS, OR SPECIAL,
        INDIRECT, INCIDENTAL, PUNITIVE, COMPENSATORY OR CONSEQUENTIAL DAMAGES OF ANY KIND WHATSOEVER (HOWEVER ARISING);
        (II) FOR ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE (REGARDLESS OF THE SOURCE OF ORIGINATION); OR (III) FOR
        ANY DIRECT DAMAGES IN EXCESS OF (IN THE AGGREGATE) FIFTY DOLLARS (U.S. $50.00).</p>

    <h2><strong>K.</strong> DISPUTES WITH BUSINESSES.</h2>
    <p>If there arises any dispute between you and any third party (including any Business), you acknowledge and agree
        that we have no obligation to become involved. In the event that you have any dispute with any third party
        (including any Business), you agree to release us (including the Site), its directors, officers, employees,
        service providers, affiliates, agents and successors from all claims, demands, and damages (actual and
        consequential) of every kind or nature, known or unknown, suspected and unsuspected, disclosed and undisclosed,
        arising out of or in any way related to such dispute.</p>

    <h2><strong>L.</strong> MODIFICATION.</h2>
    <p>We reserve the right, in our sole discretion, to modify or replace any of these Terms of Service, or change,
        suspend, or discontinue the Site or access to the Site at any time by posting a notice on the Site or by any
        other means of electronic communication. We may also impose limits on certain features or functions of the Site
        or restrict your access to parts or all of the Site without notice or liability. It is your responsibility to
        check these Terms of Service periodically for changes. Your continued use of the Site following notification of
        any changes to these Terms of Service constitutes acceptance of those changes, which will apply to your
        continued use of the Site. Your use of the Site is subject to the Terms of Service in effect at the time of such
        use.</p>

    <h2><strong>M.</strong> FEEDBACK.</h2>
    <p>As part of using our Site, we may provide you with the opportunity to submit comments, suggestions, ideas,
        feedback, information or other disclosures regarding your use of our Site (collectively, “Feedback”). You are
        not required to provide Feedback, but if you do, then you agrees that we are free to use without restriction
        (but we are not obligated to use) any Feedback you provide, including without limitation, any ideas, concepts,
        know-how, or techniques relating to the Site (or any part thereof), for any purpose and without restriction and
        without any accounting, royalty or other obligation to you. We will own all right, title and interest in and to
        the Feedback. You hereby irrevocably assign to us all right, title and interest in and to the Feedback and you
        agree to provide us any assistance we may require to document, perfect, and maintain our rights in the Feedback
        at our expense. In the event we elect to implement any such Feedback, we will do so at our own expense and will
        not attribute any Feedback to you.</p>

    <h2><strong>N.</strong> MISCELLANEOUS.</h2>
    <p>As part of using our Site, we may provide you with the opportunity to submit comments, suggestions, ideas,
        feedback, information or other disclosures regarding your use of our Site (collectively, “Feedback”). You are
        not required to provide Feedback, but if you do, then you agrees that we are free to use without restriction
        (but we are not obligated to use) any Feedback you provide, including without limitation, any ideas, concepts,
        know-how, or techniques relating to the Site (or any part thereof), for any purpose and without restriction and
        without any accounting, royalty or other obligation to you. We will own all right, title and interest in and to
        the Feedback. You hereby irrevocably assign to us all right, title and interest in and to the Feedback and you
        agree to provide us any assistance we may require to document, perfect, and maintain our rights in the Feedback
        at our expense. In the event we elect to implement any such Feedback, we will do so at our own expense and will
        not attribute any Feedback to you.</p>
    <h3>1. Notices</h3>
    <p style="padding-left: 40px">We may send notices with respect to the Site by posting on the Site. Notices shall
        become effective immediately. Any notices will be deemed delivered to the party receiving such communication on
        the date we post the notice to the Site.</p>
    <h3>2. Individual Arbitration, No Class Action Claims</h3>
    <p style="padding-left: 40px">All disputes or claims arising out of or relating in any way to your use of the Site,
        these Terms of Service, or the Privacy Policy shall be resolving by binding arbitration, except for matters that
        you may bring to small claims court. You acknowledge that you are foregoing your right to a trial by jury, and
        will not proceed in a lawsuit in state or federal court. Arbitration will be conducted by the American
        Arbitration Association (“AAA”) pursuant to AAA commercial arbitration rules, including the AAA’s Supplementary
        Procedures for Consumer-Related Disputes (where applicable), and be governed by the Federal Arbitration Act. The
        arbitrator shall honor these Terms of Service, follow applicable law, and can issue reasonable relief, including
        monetary damages as well as injunctive, declaratory or statutory mandated relief.</p>
    <p style="padding-left: 40px">Either of us may be able to recover attorney’s fees in arbitration as if the dispute
        were in court as available under applicable law. The arbitration will be conducted solely based on written
        submissions unless the arbitrator determines that a telephone or in-person hearing is necessary. A decision by
        the arbitrator (including all findings of fact and conclusions of law) shall be confidential unless otherwise
        required to be disclosed by law. A decision may also not be used in any litigation or arbitration involving any
        other person.</p>
    <p style="padding-left: 40px">Notwithstanding anything to this contrary in this section, either of us may bring a
        suit in court to enjoin infringement, unauthorized access or improper use of computer networks, Content,
        misappropriation or theft of intellectual property, or other violation of your obligations under Section B (Use
        of the Site), Section D (Content), or Section E (Rules of Conduct).</p>
    <p style="padding-left: 40px">All arbitration proceedings shall be individual arbitration. You expressly agree that
        no other claims may be joined with your claims. You further agree that you have voluntarily waived the right to
        file or join a lawsuit in state or federal court, a right to a jury trial, and the right to file or join a class
        action lawsuit, and expressly acknowledge that the parties reject class arbitration. Neither you nor we shall be
        entitled to join, sue on behalf of a putative class or consolidated claims, or arbitrate any claim as a
        representative or in a class action.</p>
    <p style="padding-left: 40px">If any part of this Section of this Agreement, with the exception of the waiver of
        class action or class arbitration, is deemed invalid or unenforceable by any arbitrator or court, that provision
        shall be severed and the balance of this Agreement shall remain in effect and shall be construed in accordance
        with its terms as if the unenforceable provision were not included. If the class action or class arbitration
        waiver is found to unenforceable, then the entire arbitration provision shall be null and void.</p>
    <p style="padding-left: 40px">The location of any dispute resolution, by arbitration or litigation, shall be in
        Boston, Massachusetts. Similarly, any action to enforcement an arbitration award must be brought in the state
        courts of the State of Massachusetts located in Boston, Massachusetts. You irrevocably consent to the
        jurisdiction of such courts.</p>
    <h3>3. Governing Law, International Use</h3>
    <p style="padding-left: 40px">These Terms &amp; Conditions will be governed by and construed in accordance with the
        internal laws of the Commonwealth of Massachusetts, without regard to its conflicts of law provisions. You agree
        to the personal jurisdiction by and venue in the state and federal courts in Middlesex County, Massachusetts,
        and waive any objection to such jurisdiction or venue.</p>
    <p style="padding-left: 40px">Although the Site may be accessible worldwide, we make no representation that
        materials on the Site are appropriate or available for use in locations outside the United States, and accessing
        the Site from territories where its use is illegal is prohibited.</p>
</div>
<div class="html-embed-3 legal w-embed">
    <h3>4. Governing Law, International Use</h3>
    <p style="padding-left: 40px">These Terms of Service, together with the Privacy Policy, represent the entire
        agreement between you and us with respect to the subject matter addressed herein and supersedes all prior to
        contemporaneous agreements or understandings, written or oral. These Terms of Service shall be binding upon and
        inure to the benefit of the parties hereto and their respective successors and lawful assigns.</p>
    <h3>5. Waiver</h3>
    <p style="padding-left: 40px">Any failure by us to enforce or exercise any provisions of these Terms of Service
        shall not constitute a waiver of that right or provision. Our failure to act with respect to a breach by you or
        others does not waive our rights to act with respect to subsequent or similar breaches.</p>
    <h3>6. Digital Millennium Copyright Act</h3>
    <p style="padding-left: 40px">If you believe any aspect of the Site infringes your copyright, you should send
        written notice of copyright infringement to our designated copyright agent at the address below. Your notice
        must meet the requirements of the Digital Millennium Copyright Act by providing the following information:</p>
    <ul style="padding-left: 40px">
        <li>a description of the copyrighted work that you claim has been infringed;</li>
        <li>a description of where the material that you claim is infringing is located on the Site;</li>
        <li>your address, telephone number, and e-mail address;</li>
        <li>a statement by you that you have a good-faith belief that the disputed use is not authorized by the
            copyright owner, its agent, or the law;</li>
        <li>a statement by you, made under penalty of perjury, that the above information in your notice is accurate and
            that you are the copyright owner or authorized to act on the copyright owner's behalf; and</li>
        <li>an electronic or physical signature of the person authorized to act on behalf of the owner of the copyright
            at issue.</li>
    </ul>
    <p style="padding-left: 40px">The address of our copyright agent for notice of claims of copyright infringement on
        the Site is as follows:</p>
    <p style="padding-left: 40px">Copyright Agent<br>
        letsbutterfly.com<br>
        165 Ames Street<br>
        Unit 3311<br>
        Marlborough, Massachusetts 01752</p>
    <h3>7. Miscellaneous</h3>
    <p style="padding-left: 40px">The section headings used herein are for convenience only and shall be of no legal
        force or effect. If any provision of this Agreement is held invalid by a court of competent jurisdiction, such
        invalidity shall not affect the enforceability of any other provisions contained in this Agreement and the
        remaining portions of this Agreement shall continue in full force and effect.</p>
</div>`
